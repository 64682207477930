import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Space } from 'antd'
import { FormattedDate, FormattedMessage } from 'react-intl'

const mapStateToProps = ({ user }) => ({ user })

const NotificationBar = ({ user }) => {
  const { expired = 0, engineExpired = 0, clientExpired = 0 } = user
  const dNow = Date.now()
  const day = 60 * 60 * 24 * 2 * 1000

  return (
    <>
      {user.id === '' && (
        <Alert
          closable
          showIcon
          message={
            <>
              <Space>
                <FormattedMessage id="topBar.profileMenu.trial" />
              </Space>
            </>
          }
          action={
            <Space>
              <Link to="/auth/register" className="btn btn-sm btn-success width-100">
                <FormattedMessage id="text.register" />
              </Link>
            </Space>
          }
          type="warning"
          className="mb-2"
        />
      )}
      {user.type === 1 && user.engineType === 2 && (
        <Alert
          closable
          showIcon
          message={
            <>
              <Space>
                <FormattedMessage id="topBar.profileMenu.trialExpired" />
                <FormattedDate
                  value={new Date(expired || 0)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                  hour="numeric"
                  minute="numeric"
                />
              </Space>
            </>
          }
          action={
            <Space>
              <Link to="/main/pricing" className="btn btn-sm btn-success width-100">
                <FormattedMessage id="text.order" />
              </Link>
            </Space>
          }
          type={`${expired - dNow <= 0 ? 'error' : 'warning'}`}
          className="mb-2"
        />
      )}
      {user.role && expired <= dNow + day && user.engineType !== 2 && (
        <Alert
          closable
          showIcon
          message={
            <>
              <Space>
                <FormattedMessage id="topBar.profileMenu.expired" />
                <FormattedDate
                  value={new Date(expired || 0)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                  hour="numeric"
                  minute="numeric"
                />
              </Space>
            </>
          }
          action={
            <Space>
              <Link to="/main/pricing" className="btn btn-sm btn-success width-100">
                <FormattedMessage id="text.order" />
              </Link>
            </Space>
          }
          type={`${expired - dNow <= 0 ? 'error' : 'warning'}`}
          className="mb-2"
        />
      )}
      {/* disable exgine expired notification */}
      {false &&
        (user.role === 'Self Engine' || user.role === 'Full Engine') &&
        engineExpired <= dNow + day && (
          <Alert
            closable
            showIcon
            message={
              <>
                <Space>
                  <FormattedMessage id="topBar.profileMenu.autobetExpired" />
                  <FormattedDate
                    value={new Date(engineExpired || 0)}
                    year="numeric"
                    month="short"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                  />
                </Space>
              </>
            }
            action={
              <Space>
                <Link to="/main/pricing" className="btn btn-sm btn-success width-100">
                  <FormattedMessage id="text.order" />
                </Link>
              </Space>
            }
            type={`${engineExpired - dNow <= 0 ? 'error' : 'warning'}`}
            className="mb-2"
          />
        )}
      {(user.role === 'Self Engine' || user.role === 'Full Engine') && clientExpired <= dNow + day && (
        <Alert
          closable
          showIcon
          message={
            <>
              <Space>
                <FormattedMessage id="topBar.profileMenu.clientExpired" />
                <FormattedDate
                  value={new Date(clientExpired || 0)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                  hour="numeric"
                  minute="numeric"
                />
              </Space>
            </>
          }
          action={
            <Space>
              <Link to="/main/pricing" className="btn btn-sm btn-success width-100">
                <FormattedMessage id="text.order" />
              </Link>
            </Space>
          }
          type={`${clientExpired - dNow <= 0 ? 'error' : 'warning'}`}
          className="mb-2"
        />
      )}
    </>
  )
}

export default connect(mapStateToProps)(NotificationBar)
